import { template as template_3c914332b9e641dbb5d34f2a15a60c39 } from "@ember/template-compiler";
const FKLabel = template_3c914332b9e641dbb5d34f2a15a60c39(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
