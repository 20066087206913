import { template as template_b2c41f6a456f475fa640f8f9d24497d2 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_b2c41f6a456f475fa640f8f9d24497d2(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
