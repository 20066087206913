import { template as template_7ecd52f76a1e41d08c6f860925cc94a9 } from "@ember/template-compiler";
const WelcomeHeader = template_7ecd52f76a1e41d08c6f860925cc94a9(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
