import { template as template_538aa2e2979e47b0843b48b43af9c5af } from "@ember/template-compiler";
import { i18n } from "discourse-i18n";
import SectionLink from "../section-link";
const SidebarCommonAllCategoriesSectionLink = template_538aa2e2979e47b0843b48b43af9c5af(`
  <SectionLink
    @linkName="all-categories"
    @content={{i18n "sidebar.all_categories"}}
    @route="discovery.categories"
    @prefixType="icon"
    @prefixValue="sidebar.all_categories"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarCommonAllCategoriesSectionLink;
